<script>

import {
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
} from "vue-feather-icons";
import Navbar from "@/components/navbar0";

import store from '@/store/store';


import { BSpinner } from 'bootstrap-vue'
import { BToast } from 'bootstrap-vue'

export default {
  data() {
    return {
      input2sql:{
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        termsAgree: false
      },
      loadingStatus: false,
    };
  },
  methods: {


    signUpFunction(){

      /*
      const loading = this.$vs.loading({
        target: this.$refs.signInButton,
        scale: '0.6',
        background: '#00967d',
        opacity: 0.5,
        color: '#fff'
      });


      this.$vs.loading({
        container: this.$refs.signInButton.$el,
        scale: '0.6',
        background: '#00967d',
        opacity: 0.5,
        color: '#fff',
      });
*/
      this.loadingStatus = true;

      this.axios({
        method:'post',
        url: '' + this.$hostname +'/register',
        data: this.input2sql,
      }).then( (res) => {
        this.loadingStatus = false;
            if (res.data.status === 'success'){
              //loading.close();
              //this.$vs.loading.close("#div-with-loading > .con-vs-loading");

              store.commit('Update_justRegistered_State', true);

              this.$cookies.set('access_token_skillrank',res.data.access_token);
              this.$cookies.set('username_skillrank',res.data.username);
              this.$cookies.set('authority_skillrank',res.data.authority);
              this.$cookies.set('confirmed_skillrank',res.data.confirmed);
              this.$cookies.set('id_skillrank',res.data.id);
              this.$cookies.set('showAccountConfirmAlert','yes');

              store.commit('UPDATE_LOGGEDIN_STATE', 1);
              store.commit('UPDATE_TOKEN', res.data.access_token);
              store.commit('UPDATE_USERNAME', res.data.username);
              store.commit('UPDATE_USER_ID', res.data.id);
              store.commit('UPDATE_USER_AUTHORITY', res.data.authority);

              this.justRegistered = true;

              	this.$router.push({path:'/vector-search'});


            } else {
              this.statusFlagRegistration = true
              //loading.close();
              //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              //this.$router.push({path:'/'})

            //  this.$vs.notify({
            //    time: 4000,
            //    title: 'Ошибка',
            //    text: res.data.status,
            //    color: 'danger'
            //  });
              this.$bvToast.toast(res.data.status, {
                title: `Ошибка`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 8000,
              });
            }
          }
      ).catch((err) => {
        this.statusFlagRegistration = true
        console.error(err);
        //loading.close();
       // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.$router.push({path:'/signup'})

      //  this.$vs.notify({
      //    time: 4000,
      //    title: 'Ошибка',
      //    text: res.data.status,
      //    color: 'danger'
      //  });
        this.$bvToast.toast(err, {
          title: `Ошибка`,
          variant: 'danger',
          solid: true,
          autoHideDelay: 8000,
        });
      });




    },


  },


  components: {
    Navbar,
    HomeIcon, UserIcon, UserCheckIcon, MailIcon, KeyIcon,
    BSpinner, BToast,

  },

};
</script>

<template>
  <div>


    <Navbar />

    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <!--<section class="bg-home bg-circle-gradiant d-flex align-items-center">-->
    <section class="bg-half-170">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Регистрация</h4>
                <form class="login-form mt-4">

                  <!--<label>Имя </label>-->
                  <!--<label>Фамилия </label>-->

                  <div class="row">
                      <!--
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Имя <span class="text-danger">*</span></label>

                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            type="text"
                            class="form-control pl-5"
                            placeholder="Имя"
                            name="s"
                            required=""
                            v-model="input2sql.first_name"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">

                        <label>Фамилия <span class="text-danger">*</span></label>

                        <div class="position-relative">
                          <user-check-icon
                            class="fea icon-sm icons"
                          ></user-check-icon>
                          <input
                            type="text"
                            class="form-control pl-5"
                            placeholder="Фамилия"
                            name="s"
                            required=""
                            v-model="input2sql.last_name"
                          />
                        </div>
                      </div>
                    </div>
                      -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Ваш Email <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            type="email"
                            class="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            required=""
                            v-model="input2sql.username"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Пароль <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Пароль"
                            required=""
                            v-model="input2sql.password"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">

                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            v-model="input2sql.termsAgree"
                          />

                          <label class="custom-control-label" for="customCheck1">
                            Нажимая кнопку «Зарегистрироваться», вы принимаете условия
                            <a href="/terms" class="text-primary">Правил</a> и
                            <a href="/license" class="text-primary">Соглашение</a> об использовании сайта.
                            </label>

                          <!--<label class="custom-control-label">
                            Нажимая кнопку «Зарегистрироваться», вы принимаете условия
                            <a href="/terms" class="text-primary">Правил</a> и
                            <a href="/license" class="text-primary">Соглашение</a> об использовании сайта.
                          </label>
                            -->
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <!--
                      <button class="btn btn-primary btn-block">
                        Зарегистрироваться
                      </button>
                      -->
                      <a size="xl" type="button" class="btn btn-primary btn-block" @click="signUpFunction">
                        <b-spinner v-if="loadingStatus"></b-spinner> Зарегистрироваться
                      </a>

                    </div>

                    <div class="col-lg-12 mt-4 text-center">
                      <h6>Или войдите с помощью учетной записи в других сервисах</h6>
                      <div class="row">
                        <div class="col-6 mt-3">
                          <a
                              class="btn btn-block btn-light"
                          >
                            <img src="min-hh-red.png" width="20" height="20" >
                            <!--<i class="mdi mdi-facebook text-primary"></i>-->
                            HH</a>
                        </div>

                        <!--end col-->

                        <div class="col-6 mt-3">
                          <a
                              class="btn btn-block btn-light"
                          >
                            <img src="careerHabrLogo1.jpg" width="20" height="20" >
                            Career.Habr
                          </a>


                      </div>

                        <!--end col-->
                      </div>
                      <!--end icon-->
                    </div>

                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                          >У вас уже есть аккаунт ?</small
                        >
                        <router-link
                          to="/login"
                          class="text-dark font-weight-bold"
                          >Войти</router-link
                        >
                      </p>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

  </div>
</template>
